/*!
 * Cape Morris
 * Zgagle
 */

/* Variables
   ----------------------------- */

$color1: #f07f3c;
$color2: #ebebeb;
$color3: #2d4454;

/* Imports
   ----------------------------- */

@import "bootstrap";

/* Fonts
   ----------------------------- */

@font-face {
    font-family: 'exo';
    src: url('../fonts/exo2.0-regular-webfont.eot');
    src: url('../fonts/exo2.0-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/exo2.0-regular-webfont.woff2') format('woff2'),
         url('../fonts/exo2.0-regular-webfont.woff') format('woff'),
         url('../fonts/exo2.0-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'exo-semi';
    src: url('../fonts/exo2.0-semibold-webfont.eot');
    src: url('../fonts/exo2.0-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/exo2.0-semibold-webfont.woff2') format('woff2'),
         url('../fonts/exo2.0-semibold-webfont.woff') format('woff'),
         url('../fonts/exo2.0-semibold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'exo-thin';
    src: url('../fonts/exo2.0-thin-webfont.eot');
    src: url('../fonts/exo2.0-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/exo2.0-thin-webfont.woff2') format('woff2'),
         url('../fonts/exo2.0-thin-webfont.woff') format('woff'),
         url('../fonts/exo2.0-thin-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Media queries
   ----------------------------- */

@mixin phone {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 992px) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: 1200px) {
    @content;
  }
}

/* Modal
   ----------------------------- */

@media (min-width: 768px) {
    .modal-dialog {
        width: 800px;
        margin: 30px auto;
    }
}

#dlaczego-modal {
    .modal-content {
        padding: 0 50px 30px 50px;
        text-align: left;
        .close {
            margin: 30px;
        }
        h3 {
            color: $color1;
        }
        p {
            font-weight: 200;
            font-size: 20px;
            margin-bottom: 40px;
        }
    }
}

/* Core styles
   ----------------------------- */

body {
    font-family: exo;
    color: #1f2e3b;
    font-size: 14px;
    background: #fffefa;
}

a {
    color: #000000;
    text-decoration: none;
}

a:hover {
    color: #fc9a42;
    text-decoration: none;
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 28px;
 }

h3 {
    font-size: 24px;
 }

h4 {
    font-size: 20px;
 }

h5 {
    font-size: 18px;
 }

/* Custom styles
   ----------------------------- */

.no-padding {
    padding: 0 !important;
}

.row-no-padding {
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

textarea:focus, input:focus{
    outline: 0;
}

.form-control:focus, select:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.container-fluid {
    padding:0;
}

.form-control {
    box-shadow: none;
    &::-ms-clear { 
        display: none; 
    }
}

.justify {
    text-align: justify;
}

.no-scrolling {
  height: 100%;
  overflow: hidden;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */

/* Buttons
   ----------------------------- */

.btn-orange {
    background: $color3;
    padding: 16px 40px;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 30px;
    text-align: center;
    border: 0;
    display: inline-block;
    &:hover {
        color: #fff;
        background: rgba($color3, 0.8);
        text-decoration: none;
    }
    &:focus {
        color: #fff;
        text-decoration: none;
    }
    &:active {
        color: #fff;
        text-decoration: none;
    }
}

.btn-white {
    background: #fff;
    padding: 25px 40px;
    color: $color1;
    font-size: 18px;
    border-radius: 0;
    text-align: center;
    border: 0;
    display: inline-block;
    &:hover {
        color: #fff;
        background: rgba($color3, 0.8);
        text-decoration: none;
    }
    &:focus {
        color: #fff;
        text-decoration: none;
    }
    &:active {
        color: #fff;
        text-decoration: none;
    }
}

.btn-rounded {
    background: $color1;
    padding: 32px 70px;
    color: #fff;
    font-size: 22px;
    border-radius: 10px;
    text-align: center;
    border: solid 2px $color1;
    display: inline-block;
    outline: 0;
    span {
        font-size: 16px;
    }
    &:hover {
        color: $color1;
        background: #fff;
        text-decoration: none;
        outline: 0;
    }
    &:focus {
        color: #fff;
        text-decoration: none;
        background: $color1;
        outline: 0;
        &:hover {
            color: $color1;
            background: #fff;
            text-decoration: none;
        }
    }
    &:active {
        color: #fff;
        text-decoration: none;
        background: $color1;
    }
}


/* Mobile menu
   ----------------------------- */

.navbar-collapse.in, .navbar-collapse.collapsing {
    overflow-y: initial;
    padding-left: 0;
    margin-top: 85px;
    box-shadow: none;
    border: 0;
    background: rgba(0,0,0,0.9);
    height: 100vh;
    max-height: initial;
    .navbar-nav {
        margin-left: 0;
        li a {
            color: #ffffff;
            font-size: 18px;
            letter-spacing: 2px;
            padding: 6% 10%;
        }
    }
}

/* Sections
   ----------------------------- */

header {
    width: 100%;
    margin-top: 60px;
    transition: all 0.5s;
    &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        background: #f2f2f0;
        z-index: 10;
        margin-top: 0;
        padding: 20px 0 10px 0;
        display: block;
    }
    .logo-wrapper {
        height: 43px;
        padding: 8px 30px;
        border-right: solid 1px #d9d9d9;
        float: left;
        @include tablet {
            border-right: solid 0px #d9d9d9;
        }
        .logo {
            width: 120px;
        }
    }
    .slogan {
        float: left;
        padding: 13px 40px;
        border-radius: 20px;
        color: #aa9f9f;
        @include tablet {
            display: none;
        }
    }
    .add-question {
        float: right;
        position: relative;
        .btn-add {
            padding: 15px 81px 15px 30px;
            border: solid 1px $color1;
            color: $color1;
            display: inline-block;
            position: relative;
            top: -4px;
            text-decoration: none;
            .plus {
                height: 100%;
                width: 51px;
                border-left: solid 1px $color1;
                position: absolute;
                right: 0;
                top: 0;
                .cross {
                  margin: 18px 0 0 18px;
                  display: inline-block;
                  position: relative;
                  width: 13px;
                  height: 13px;
                }
                .cross:before, .cross:after {
                  position: absolute;
                  left: 7px;
                  content: ' ';
                  height: 13px;
                  width: 1px;
                  background-color: $color1;
                }
                .cross:after {
                  transform: rotate(90deg);
                }
            }
            &:hover {
                .plus {
                    background: $color1;
                    .cross:before, .cross:after {
                      background-color: #fffefa;
                    }
                }
            }
        }
    }
}

section.home {
    width: 100%;
    height: 100vh;
    position: relative;
    .search-wrapper {
        max-width: 100%;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        .logo-zgagle {
            margin-bottom: 70px;
            @include phone {
                max-width: 50%;
                margin-bottom: 30px;
            }
        }
        .search-bar {
            padding: 0;
            border: solid 1px #bbbbbb;
            color: $color1;
            display: inline-block;
            position: relative;
            width: 790px;
            @include tablet {
                max-width: 90%;
            }
            .search-input {
                height: 85px;
                width: 100%;
                background: transparent;
                border: 0;
                font-size: 22px;
                padding: 0 140px 0 30px;
                @include phone {
                    height: 55px;
                    padding: 0 20px;
                }
            }
            .btn-search {
                height: 100%;
                width: 105px;
                border: 0;
                border-left: solid 1px #bbbbbb;
                background: 0;
                position: absolute;
                box-shadow: none;
                right: 0;
                top: 0;
                cursor: pointer;
                &:hover {
                    background: #bbbbbb;
                    .cross:before, .cross:after {
                      background-color: #fffefa;
                    }
                    svg {
                        path {
                            fill: #fffefa;
                        }
                    }
                }
                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 30px;
                    path {
                        fill: #bbbbbb;
                    }
                }
                @include tablet {
                    svg {
                        width: 20px;
                    }
                }
            }
        }
    }
    .btn-down {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: $color1;
        position: absolute;
        bottom: 40px;
        left: 50%;
        margin-left: -40px;
        cursor: pointer;
        @include phone {
            width: 50px;
            height: 50px;
        }
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 25px;
            path {
                fill: #fff;
            }
        }
        &:hover {
            transition: all 0.3s ease;
            -webkit-transform: scale(0.9);
            -ms-transform: scale(0.9);
            transform: scale(0.9);
        }
    }
}

section.subpage {
    width: 100%;
    height: 174px;
    position: relative;
    @include phone {
        height: 70px;
    }
    header {
        width: 100%;
        padding: 50px 0 55px 0;
        margin-top: 0;
        transition: all 0.5s;
        @include phone {
            padding: 2px 0 19px 0;
        }
        &.sticky {
            position: fixed;
            top: 0;
            left: 0;
            background: #f2f2f0;
            z-index: 10;
            margin-top: 0;
            padding: 10px 0 10px 0;
            display: block;
        }
        .left {
            position: relative;
            top: 10px;
            .logo-wrapper {
                height: 43px;
                padding: 8px 30px;
                border-right: solid 1px #d9d9d9;
                float: left;
                @include tablet {
                    border-right: solid 0px #d9d9d9;
                }
                .logo {
                    width: 105px;
                }
            }
            .slogan {
                float: left;
                padding: 13px 40px;
                border-radius: 20px;
                color: #aa9f9f;
                @include tablet {
                    display: none;
                }
            }
        }
        .search-wrapper {
            width: 450px;
            max-width: 100%;
            float: right;
            position: relative;
            top: initial;
            left: initial;
            -webkit-transform: none;
            transform: none;
            text-align: center;
            @include tablet {
                display: none;
            }
            @include phone {
                width: 200px;
            }
            .search-bar {
                padding: 0;
                border: solid 1px #bbbbbb;
                color: $color1;
                display: inline-block;
                position: relative;
                width: 100%;
                @include tablet {
                    max-width: 90%;
                }
                .search-input {
                    height: 57px;
                    width: 100%;
                    background: transparent;
                    border: 0;
                    font-size: 22px;
                    padding: 0 130px 0 30px;
                    @include phone {
                        height: 55px;
                        padding: 0 20px;
                    }
                }
                .btn-search {
                    height: 100%;
                    width: 105px;
                    border: 0;
                    border-left: solid 1px #bbbbbb;
                    position: absolute;
                    background: transparent;
                    right: 0;
                    top: 0;
                    cursor: pointer;
                    @include phone {
                        width: 50px;
                    }
                    &:hover {
                        background: #bbbbbb;
                        .cross:before, .cross:after {
                          background-color: #fffefa;
                        }
                        svg {
                            path {
                                fill: #fffefa;
                            }
                        }
                    }
                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        width: 30px;
                        path {
                            fill: #bbbbbb;
                        }
                    }
                    @include tablet {
                        svg {
                            width: 20px;
                        }
                    }
                }
            }
        }
    }
}

section.articles {
    width: 100%;
    padding: 100px 0;
    [class*="col-"] {
        padding-left: 35px;
        padding-right: 35px;
    }
    transition: all 25s;
    article {
        width: 100%;
        min-height: 300px;
        background: $color1;
        padding: 49px;
        color: #fff;
        font-size: 15px;
        font-weight: 200;
        position: relative;
        overflow: hidden;
        transition: all 0.3s;
        &:hover {
            transform: translateY(-7px);
        }
        h1 {
            font-family: exo;
            margin-bottom: 65px;
            font-size: 43px;
            line-height: 62px;
            span {
                font-family: exo; 
            }
        }
        &.light {
            background: $color2;
            color: #000;
        }
        &.top {
            margin-top: 70px;
            min-height: 350px;
        }
        &.small {
            min-height: 330px;
            h4 {
                margin-bottom: 40px;
                font-family: exo-semi;
                span {
                    color: $color1;
                }
            }
            .justify {
                line-height: 20px;
            }
        }
        &.big {
            height: 678px;
            background: $color1 url('../images/Cintix_box.jpg');
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            text-align: center;
            @include phone {
                display: none;
            }
            h3 {
                color: #000;
                margin-bottom: 80px;
                font-family: exo-semi;
            }
            .logo {
                margin-bottom: 50px;
                max-width: 100%;
            }
            .pack {
                max-width: 100%;
            }
            .corner {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 125px 125px;
                border-color: transparent transparent $color1 transparent;
                cursor: pointer;
                svg {
                    position: absolute;
                    bottom: -103px;
                    right: 17px;
                    z-index: 99;
                    width: 25px;
                    transform: rotate(-90deg);
                    path {
                        fill: #fff;
                    }
                }
            }
        }
        .justify {
            line-height: 30px;
        }
        &.bg {
            // background: $color1 url('../images/articlebg.jpg');
            // background-position: -200px center;
            // background-repeat: no-repeat;
            &:hover {
                transform: none;
            }
        }
        .top-panel {
            position: absolute;
            top: 0;
            left: 0;
            height: 50%;
            width: 100%;
            background: $color1 url('../images/milk.jpg');
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .bottom-panel {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 50%;
            width: 100%;
            padding: 20px;
            p:first-child {
                font-size: 22px;
                margin-bottom: 15px;
            }
            p:last-child {
                font-family: exo-semi;
                font-size: 14px;
                height: 83px;
                font-weight: 200;
                overflow: hidden;
                color: #999;
            }
        }
        &.faq {
            background: $color1 url('../images/faqbg.jpg');
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            text-align: center;

            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
            flex-direction: column;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;
            h1 {
                font-size: 88px;
                margin-bottom: 30px;
            }
            p {
                font-size: 18px;
                font-weight: 700;
            }
        }
    }
    .pad-fix {
        @include tablet {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
    .top-fix {
        @include tablet {
            margin-top: 70px;
        }
    }
}

section.baner-coffe {
    width: 100%;
    background-image: url('../images/contix_pack_bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 140px 30px;
    @include tablet {
        padding: 60px 30px;
    }
    .baner-coffe__pack {
        @include tablet {
            display: block;
            margin: 0 auto;
            width: 60%;
        }
    }
    .baner-coffe__text {
        @include tablet {
            padding-top: 40px;
            display: block;
            margin: 0 auto;
        }
    }
    
    // height: 465px;
    // position: relative;
    // background: url('../images/banerbg.png');
    // margin-bottom: 150px;
    // padding-top: 85px;
    // .container {
    //     position: relative;
    // }
    // .text-wrapper {
    //     width: 51%;
    //     margin: 0;
    //     h1 {
    //         font-size: 62px;
    //         color: #000;
    //         margin: 0;
    //         letter-spacing: 1px;
    //         span {
    //             font-family: exo-semi;
    //         }
    //         .orange {
    //             color: $color1;
    //         }
    //     }
    //     p {
    //         font-size: 18px;
    //         margin-top: 60px;
    //     }
    // }
    // .pack {
    //     position: absolute;
    //     right: 0;
    //     bottom: -180px;
    // }
}

section.video {
     margin: 50px auto 0 auto;
     max-width: 900px;
     padding: 0 30px;
}
.video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}
.video-ad {
    // margin: 50px auto 0 auto;
    // display: block;
    // width: 100% !important;
    // height: auto !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

section.baner {
    width: 100%;
    height: 515px;
    position: relative;
    background: $color1 url('../images/articlebg.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 150px;
    .text-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: 0;
        transform: translate(-50%,-50%);
        h1 {
            font-size: 62px;
            color: #fff;
            margin: 0;
            text-align: center;
            letter-spacing: 1px;
            span {
                font-family: exo-semi;
            }
        }
        p {
            font-size: 22px;
            text-align: center;
            margin-top: 60px;
        }
    }
    .pack {
        position: absolute;
        bottom: -180px;
        left: 50%;
        transform: translateX(-50%);
    }
    &.blank {
        height: 500px;
        background: none;
        margin-bottom: 0;
        h1 {
            color: $color1;
            font-size: 52px;
            font-family: exo-semi;
        }
    }
    &.contix {
        height: 420px;
        margin-bottom: 0;
        padding: 0 20px;
        background: $color1 url('../images/ban2.jpg');
        background-size: cover;
        background-position: center center;
        h2 {
            color: #fff;
            padding: 80px 0 40px 0;
            font-size: 28px;
            line-height: 36px;
            font-family: exo-semi;
        }
        img {
            width: 300px;
            margin-top: 120px;
        }
        p {
            color: #fff;
            font-size: 24px;
            margin-top: 40px;
        }
        @include tablet {
            height: auto;
            text-align: center;
        }
        .opakowanie {
            margin-top: 150px;
            @include tablet {
                display: block;
                margin: 0 auto;
                position: relative;
                margin-top: 50px;
            }
        }
    }
}

section.icons {
    width: 100%;
    position: relative;
    text-align: center;
    padding: 0 20px;
    .container {
        padding: 90px 0;
        background: url('../images/kawasowa.png') left bottom;
        background-repeat: no-repeat;
        @include tablet {
            background: transparent;
        }
    }
    h1 {
        font-family: exo;
        margin-bottom: 100px;
        img {
            padding-left: 30px;
            margin-top: -10px;
            @include tablet {
                margin: 40px auto 0 auto;
                display: block;
            }
        }
    }
    .icon-wrapper {
        height: 120px;
        @include tablet {
            height: 80px;
        }
        img {
            margin-bottom: 50px;
            @include tablet {
                margin-bottom: 30px;
            }
        }
    }
    p {
        font-size: 18px;
        font-family: exo-semi;
        @include tablet {
            margin-bottom: 50px;
        }
        span {
            color: $color1;
        }
    }
    .btn-rounded {
        margin-top: 130px;
        @include tablet {
            margin-top: 50px;
            float: none !important;
        }
    }
}

section.title {
    padding: 0 20px;
    background: $color1;
    .btn-back {
        width: 85px;
        height: 85px;
        position: relative;
        background: #df7435;
        float: left;
        .arrow {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
    h5 {
        color: #fff;
        font-size: 14px;
        letter-spacing: 1px;
        padding: 25px 0 0 60px;
        display: inline-block;
        // &:before {
        //     content: "\f0f6";
        //     padding-right: 35px;
        //     font-family: FontAwesome;
        // }
        &.clean {
            text-align: center;
            &:before {
                content: " ";
                padding-right: 0;
            }
        }
    }
}

section.post-image {
    height: 315px;
    background: $color1 url('../images/Cintix_box.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

section.plain {
    padding: 80px 20px;
    text-align: center;
    font-size: 22px;
}

section.ask-form {
    padding: 110px 20px;
    background: #211d29;
    color: #fff;
    .container {
        max-width: 1000px
    }
    .btn-close {
        width: 97px;
        height: 97px;
        @include phone {
            width: 50px;
            height: 50px;
        }
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        z-index: 1041;
        -webkit-backface-visibility: hidden;
        &:hover > .cross-close {
            transform: rotate(270deg);
            transform-origin: center center;
            transition: all 0.6s ease;
        }
        .cross-close {
            position: absolute;
            margin: auto;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
    h1 {
        text-align: center;
        font-family: exo-thin;
        span {
            font-family: exo-semi;
        }
    }
    p {
        text-align: center;
        font-size: 16px;
        font-family: exo-thin;
        margin-top: 20px;
    }
    form {
        margin-top: 60px;
        .form-control {
            border: solid 1px #fff;
            height: 85px;
            background: transparent;
            border-radius: 0;
            margin-bottom: 30px;
            padding: 35px;
            color: #fff;
            font-size: 16px;
        }
        .form-control::-moz-placeholder {
          color: #fff;
          font-size: 16px;
          opacity: 1;
        }
        .form-control:-ms-input-placeholder {
          color: #fff;
          font-size: 16px;
        }
        .form-control::-webkit-input-placeholder {
          color: #fff;
          font-size: 16px;
        }
        textarea {
            width: 100%;
            border: solid 1px #fff;
            height: 200px;
            background: transparent;
            border-radius: 0;
            margin-bottom: 30px;
            padding: 35px;
            color: #fff;
            font-size: 16px;
        }
        textarea::-moz-placeholder {
          color: #fff;
          font-size: 16px;
          opacity: 1;
        }
        textarea:-ms-input-placeholder {
          color: #fff;
          font-size: 16px;
        }
        textarea::-webkit-input-placeholder {
          color: #fff;
          font-size: 16px;
        }
        textarea:focus::-webkit-input-placeholder { color:transparent; }
        textarea:-moz-placeholder { color:transparent; } /* FF 4-18 */
        textarea::-moz-placeholder { color:transparent; } /* FF 19+ */
        textarea:-ms-input-placeholder { color:transparent; } /* IE 10+ */
    }
    label {
        font-family: exo-thin;
    }
    .btn-white {
        color: #211d29;
        &:hover {
            background: #fff;
            transition: all 0.3s ease;
            -webkit-transform: scale(0.9);
            -ms-transform: scale(0.9);
            transform: scale(0.9);
        }
    }
}

section.article {
    padding: 90px 20px;
    .back {
        margin-top: 40px;
    }
    .content {
        h1 {
            font-size: 52px;
            font-family: exo-semi;
            color: $color1;
            margin-bottom: 60px;
        }
        h3 {
            margin: 0;
            line-height: 40px;
            font-size: 28px;
        }
        p {
            color: #6f6f6f;
            margin-top: 50px;
            font-size: 16px;
            font-family: exo;
            line-height: 32px;
        }
    }
    .sidebar {
        width: 100%;
        padding: 35px 35px 35px 85px;
        background: #f7f7f7;
        h3 {
            color: #000;
            font-size: 26px;
            font-family: exo-semi;
        }
        .list {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
                padding: 15px 0 15px 35px;
                background: url('../images/listarrow.png') no-repeat left 20px;
                a {
                    color: #6e6e6e;
                    font-family: exo-semi;
                    font-size: 16px;
                }
            }
        }
        .social-wrapper {
            color: $color1;
            h3 {
                margin-top: 40px;
                margin-bottom: 40px;
            }
            a {
                color: $color1;
                font-size: 19px;
                padding-right: 45px;
            }
        }
    }
}

section.pre-footer {
    width: 100%;
    padding: 60px 0;
    background: #e4e4e4;
    p {
        font-size: 18px !important;
        color: #818181;
        line-height: 22px;
    }
}

section.socialmedia {
    padding: 50px 20px 40px 20px;
    color: $color1;
    text-align: center;
    p {
        display: inline-block;
        margin-right: 20px;
    }
    a {
        color: $color1;
        font-size: 19px;
        padding-right: 15px;
    }
}

footer {
    width: 100%;
    background: #f2f2f0;
    ul {
        list-style: none;
        overflow: auto;
        padding: 0;
        li {
            float: left;
            padding: 75px 10px 27px 10px;
            @include phone {
                padding: 10px;
            }
            a {
                color: #5f5f5f;
                text-transform: uppercase;
                font-size: 12px;
                font-family: exo;
                letter-spacing: 1px;
            }
        }
    }
    .logo-footer {
        padding: 77px 10px 27px 10px;
        @include phone {
            padding: 10px 10px 20px 10px;
            margin: 0 auto;
            display: table;
        }
        color: #9e9e9e;
        text-transform: uppercase;
        font-size: 12px;
        font-family: exo;
        letter-spacing: 1px;
        display: inline-block;
    }
    .lekam-logo {
        padding: 70px 0;
        @include phone {
            padding: 30px 10px 20px 10px;
            margin: 0 auto;
            display: block;
        }
    }
    .mini {
        font-size: 11px;
        line-height: 20px;
        color: #9d9d9c;
        margin-left: 30px;
        padding: 60px 0;
        @media (max-width: 1207px) {
            display: none;
        }
    }
}

section.popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 100vh;
    background: rgba($color1, 0.9);
    color: #fff;
    padding: 70px 20px;
    .btn-close {
        width: 97px;
        height: 97px;
        @include phone {
            width: 50px;
            height: 50px;
        }
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        z-index: 1041;
        -webkit-backface-visibility: hidden;
        &:hover > .cross-close {
            transform: rotate(270deg);
            transform-origin: center center;
            transition: all 0.6s ease;
        }
        .cross-close {
            position: absolute;
            margin: auto;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
    h1 {
        text-align: center;
        font-family: exo-thin;
        span {
            font-family: exo-semi;
        }
    }
    p {
        text-align: center;
        font-size: 16px;
        font-family: exo-thin;
        margin-top: 20px;
    }
    form {
        margin-top: 60px;
        .form-control {
            border: solid 1px #fff;
            height: 85px;
            background: transparent;
            border-radius: 0;
            margin-bottom: 30px;
            padding: 35px;
            color: #fff;
            font-size: 16px;
        }
        .form-control::-moz-placeholder {
          color: #fff;
          font-size: 16px;
          opacity: 1;
        }
        .form-control:-ms-input-placeholder {
          color: #fff;
          font-size: 16px;
        }
        .form-control::-webkit-input-placeholder {
          color: #fff;
          font-size: 16px;
        }
        .form-control:focus::-webkit-input-placeholder { color:transparent; }
        .form-control:-moz-placeholder { color:transparent; } /* FF 4-18 */
        .form-control::-moz-placeholder { color:transparent; } /* FF 19+ */
        .form-control:-ms-input-placeholder { color:transparent; } /* IE 10+ */
        textarea {
            width: 100%;
            border: solid 1px #fff;
            height: 200px;
            background: transparent;
            border-radius: 0;
            margin-bottom: 30px;
            padding: 35px;
            color: #fff;
            font-size: 16px;
        }
        textarea::-moz-placeholder {
          color: #fff;
          font-size: 16px;
          opacity: 1;
        }
        textarea:-ms-input-placeholder {
          color: #fff;
          font-size: 16px;
        }
        textarea::-webkit-input-placeholder {
          color: #fff;
          font-size: 16px;
        }
        textarea:focus::-webkit-input-placeholder { color:transparent; }
        textarea:-moz-placeholder { color:transparent; } /* FF 4-18 */
        textarea::-moz-placeholder { color:transparent; } /* FF 19+ */
        textarea:-ms-input-placeholder { color:transparent; } /* IE 10+ */
    }
    label {
        font-family: exo-thin;
    }
    .btn-white {
        &:hover {
            color: $color1;
            background: #fff;
            transition: all 0.3s ease;
            -webkit-transform: scale(0.9);
            -ms-transform: scale(0.9);
            transform: scale(0.9);
        }
    }
}

.loadMore {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: $color1;
    cursor: pointer;
    margin: 70px auto 0 auto;
    color: #fff;
    padding: 24px 0 0 0;
    line-height: 16px;
    transition: all 0.3s ease;
    &:hover {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
    }
}

.ui-menu .ui-menu-item {
    position: relative;
    margin: 0;
    padding: 15px;
    cursor: pointer;
    min-height: 0;
    /* support: IE7; */
    /* support: IE10, see #8844; */
    list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
    &:last-child {
        background: #6F6F6F;
        color: #fff;
    }
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
    border: 0px solid #999999;
    background: $color1;
    font-weight: normal;
    color: #fff;
}

.elementShow {
  overflow: hidden;
  margin-left: -15px;
}

.elementShow .short:nth-child(2n) {
  margin-left: 38px;
  width: 29.9%;
    @include phone {
    width: 100%;
    margin-left: 0;
  }
}

.elementShow .short:nth-child(3n) {
  width: 29.9%;
  margin-left: 23px;
  @include phone {
    width: 100%;
    margin-left: 0;
  }
}

